import React from 'react'
import imgBlog1 from '../../assets/blog/blog1.png'
import imgBlog2 from '../../assets/blog/blog2.png'
import imgBlog3 from '../../assets/blog/blog3.png'

export default function Blog() {

  return (
<>

    <div className="page-wrapper">
        <section className="blog-one blog-one__grid">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="blog-one__single">
                            <div className="blog-one__image">
                                <div className="blog-one__image-inner">
                                    <img src={imgBlog1} alt="" />
                                    <a href='https://vellasquesenegrelli.blogspot.com/' target="_blank" rel="noreferrer" className="blog-one__image-link"><i className="fa fa-link"></i></a>
                                </div>{/*<!-- /.blog-one__image-inner -->*/}
                            </div>{/*<!-- /.blog-one__image -->*/}
                            <div className="blog-one__content">
                                <div className="blog-one__date"><span>18</span>Abr</div>{/*<!-- /.blog-one__date -->*/}
                                <h3 className="blog-one__title"><a href='https://vellasquesenegrelli.blogspot.com/' target="_blank" rel="noreferrer" >Penal</a></h3>{/*<!-- /.blog-one__title -->*/}
                                <p className="blog-one__text">Atuando em acompanhamento e defesa em sede de inquéritos policiais..</p>{/*<!-- /.blog-one__text -->*/}
                                <div className="blog-one__bottom">
                                    <a className="blog-one__link" href='https://vellasquesenegrelli.blogspot.com/' target="_blank" rel="noreferrer" ><span>Mais detalhes</span> <i className="fas fa-long-arrow-alt-right"></i></a>
                                    <a href='https://vellasquesenegrelli.blogspot.com/' target="_blank" rel="noreferrer" className="blog-one__share"><i className="fa fa-share-alt"></i></a>
                                </div>{/*<!-- /.blog-one__bottom -->*/}
                            </div>{/*<!-- /.blog-one__content -->*/}
                        </div>{/*<!-- /.blog-one__single -->*/}
                    </div>{/*<!-- /.col-lg-4 -->*/}
                    <div className="col-lg-4">
                        <div className="blog-one__single">
                            <div className="blog-one__image">
                                <div className="blog-one__image-inner">
                                    <img src={imgBlog2} alt="" />
                                    <a href='https://vellasquesenegrelli.blogspot.com/' target="_blank" rel="noreferrer" className="blog-one__image-link"><i className="fa fa-link"></i></a>
                                </div>{/*<!-- /.blog-one__image-inner -->*/}
                            </div>{/*<!-- /.blog-one__image -->*/}
                            <div className="blog-one__content">
                                <div className="blog-one__date"><span>18</span>Abr</div>{/*<!-- /.blog-one__date -->*/}
                                <h3 className="blog-one__title"><a href='https://vellasquesenegrelli.blogspot.com/'target="_blank" rel="noreferrer" >Ambiental</a></h3>{/*<!-- /.blog-one__title -->*/}
                                <p className="blog-one__text">Esse ramo do Direito busca regular as atividades empresariais e defender a preservação do meio ambiente.</p>{/*<!-- /.blog-one__text -->*/}
                                <div className="blog-one__bottom">
                                    <a className="blog-one__link" href='https://vellasquesenegrelli.blogspot.com/'target="_blank" rel="noreferrer"><span>Mais detalhes</span> <i className="fas fa-long-arrow-alt-right"></i></a>
                                    <a href='https://vellasquesenegrelli.blogspot.com/' target="_blank" rel="noreferrer" className="blog-one__share"><i className="fa fa-share-alt"></i></a>
                                </div>{/*<!-- /.blog-one__bottom -->*/}
                            </div>{/*<!-- /.blog-one__content -->*/}
                        </div>{/*<!-- /.blog-one__single -->*/} 
                    </div>{/*<!-- /.col-lg-4 -->*/}
                    <div className="col-lg-4">
                        <div className="blog-one__single">
                            <div className="blog-one__image">
                            <div className="blog-one__image-inner">
                                    <img src={imgBlog3} alt="" />
                                    <a href='https://vellasquesenegrelli.blogspot.com/' target="_blank" rel="noreferrer" className="blog-one__image-link"><i className="fa fa-link"></i></a>
                                </div>{/*<!-- /.blog-one__image-inner -->*/}
                            </div>{/*<!-- /.blog-one__image -->*/}
                            <div className="blog-one__content">
                                <div className="blog-one__date"><span>18</span>Abr</div>{/*<!-- /.blog-one__date -->*/}

                                <h3 className="blog-one__title"><a href='https://vellasquesenegrelli.blogspot.com/'target="_blank" rel="noreferrer">Previdenciário</a></h3>{/*<!-- /.blog-one__title -->*/}
                                <p className="blog-one__text">O Direito Previdenciário, como ramo do direito público autônomo, tem o objetivo de regulamentar as relações entre o sistema previdenciário e o beneficiário.</p>{/*<!-- /.blog-one__text -->*/}
                                <div className="blog-one__bottom">
                                    <a className="blog-one__link" href='https://vellasquesenegrelli.blogspot.com/'target="_blank" rel="noreferrer"><span>Mais detalhes</span> <i className="fas fa-long-arrow-alt-right"></i></a>
                                    <a href='https://vellasquesenegrelli.blogspot.com/' target="_blank" rel="noreferrer"className="blog-one__share"><i className="fa fa-share-alt"></i></a>
                                </div>{/*<!-- /.blog-one__bottom -->*/}
                            </div>{/*<!-- /.blog-one__content -->*/}
                        </div>{/*<!-- /.blog-one__single -->*/}
                    </div>{/*<!-- /.col-lg-4 -->*/}
                </div>{/*<!-- /.row -->*/}
            </div>{/*<!-- /.container -->*/}
        </section>{/*<!-- /.blog-one -->*/}
    </div>{/*<!-- /.page-wrapper -->*/}    
</>
  )
}

