import React from 'react';
import Routes from './routes';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/css/style.css';
import './assets/css/animate.css';
import { BrowserRouter as Router } from 'react-router-dom';

const App: React.FC = () => (

<Router basename={'/'} >
        <Routes />
</Router>
) 
  

export default App;