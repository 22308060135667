

import React, { useState,useRef,useCallback } from 'react'
import apple from '../../assets/img/apple-touch-icon.webp'
import { Link } from "react-scroll";
import emailjs from '@emailjs/browser';

import ChatBot from './ChatBot';
import Toast from '../Toast/Toast';
import success from '../Toast/Image/check.svg';
import about from '../../assets/img/about.jpg'
import Blog from '../blog/index'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useInView } from "react-intersection-observer";

export default function Home() {

  
  const goToBottom = () =>{ 
    window.scrollTo({ 
      top: (document.body.scrollHeight - 1000), 
      behavior: 'smooth',
    }); 
    setActive('Home')
  }; 

  const goToTop = () =>{ 
    window.scrollTo({ 
      top: (0), 
      behavior: 'smooth',
    }); 
    setActive('Blog')
  }; 

  const goToStart = () =>{ 
    window.scrollTo({ 
      top: (15), 
      behavior: 'smooth',
    }); 
    setActive('Home')
  };

  const [home, sectionHome] = useInView({ threshold: 0.1 });
  const [history, sectionHistory] = useInView({ threshold: 0.5 });
  const [services, sectionServices] = useInView({ threshold: 0.1 });
  const [contact, sectionContato] = useInView({ threshold: 0.5 });

  const goToHistory = () =>{ 
    window.scrollTo({ 
      top: (800), 
      behavior: 'smooth',
    }); 
    setActive('Home')
  };

  const goToPratica = () =>{ 
    window.scrollTo({ 
      top: (1600), 
      behavior: 'smooth',
    }); 
    setActive('Home')
  };

  const goToFacebook = () =>{ 
    window.open('https://www.facebook.com/VellasqueseNegrelli/'); 
  };

  const goToInstagram = () =>{ 
    window.open('https://www.instagram.com/vellasquesenegrelli/'); 
  };

const [active, setActive] = useState('Home');
const [list, setList] = useState([]);

const showToast = useCallback((type) => {
  let toastProperties = null;
  const id = Math.floor((Math.random() * 101) + 1);
  switch(type) {
    case 'success':
      toastProperties = {
        id,
        title: '',
        description:'Mensagem enviada !',
        backgroundColor: '#f0ad4e',
        icon: success
      }
      break;
      case 'enviar':
        toastProperties = {
          id,
          title: '',
          description:'Enviando...',
          backgroundColor: '#f0ad4e',
          icon: success
        }
        break;  
    case 'successNews':
        toastProperties = {
          id,
          title: '',
          description:'Cadastro enviado !',
          backgroundColor: '#f0ad4e',
          icon: success
        }
      break;
      default:
        setList([]);
  }
  setList([...list, toastProperties]);
},[list])


const formEmail = useRef();
const formNews = useRef();

const sendEmail = (e) => {
  showToast('enviar');
  e.preventDefault();
  emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      formEmail.current,
      process.env.REACT_APP_PUBLIC_KEY
    )
    .then(
      (result) => {
        setTimeout(function() {
          showToast('success');
          var form = document.getElementById('formDados');
          form.reset()
        }, 2000);
      },
      (error) => {
      }
    );
};

const sendNewsletter = (e) => {
  showToast('enviar');
  e.preventDefault();
  emailjs
    .sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_IDB,
      formNews.current,
      process.env.REACT_APP_PUBLIC_KEY
    )
    .then(
      (result) => {
        showToast('successNews');
        var form = document.getElementById('formNews');
        form.reset()
      },
      (error) => {
        
      }
    );
};




const handleWhats = () => {
  window.location.href='https://wa.me/+554732796430'
}

const handleMaps = () => {
  window.location.href='https://goo.gl/maps/aa6ZJ2gDsEz1udym9'
}

  return (
    <div>
    <Toast
    toastList={list}
    autoDelete={true} 
  />


   <header id="header" className="fixed-top ">
         <div className="container d-flex align-items-center justify-content-lg-between" style={{justifyContent: 'center'}}>
         <h1 className="logo me-auto me-lg-0"><p><img src={apple} className="img-fluid" alt=""></img></p></h1>   
            <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              
               <li onClick={goToStart} >
                <p className="nav-link scrollto" onClick={goToStart} style={sectionHome ?{color:'#DBBE44'}:{color:'#FFFFFF'}}>Home</p>
              </li>

               <li onClick={goToHistory}>
                <p className="nav-link scrollto" onClick={goToHistory } style={sectionHistory ?{color:'#DBBE44'}:{color:'#FFFFFF'}}>História</p>
              </li>

               <li onClick={goToPratica}>
                <p className="nav-link scrollto" onClick={goToPratica} style={sectionServices ?{color:'#DBBE44'}:{color:'#FFFFFF'}}>Serviços</p>
              </li>
               {/*<li><a style={colorD?{color:'#DBBE44'}:{color:'#FFFFFF'}}className="nav-link scrollto" href="#team">Equipe</a></li>*/}
               <li onClick={goToBottom}>
                <p className="nav-link scrollto" onClick={goToBottom}style={sectionContato ?{color:'#DBBE44'}:{color:'#FFFFFF'}}>Contato </p>
              </li>

               <li onClick={goToTop}>
                <p className="nav-link scrollto" onClick={goToTop} style={{color:'#FFFFFF'}}>Blog</p>
               </li>
            </ul>
            
            </nav>

            <p style={{cursor:'pointer'}} className="get-started-btn scrollto" onClick={goToStart}>
              <Link activeClass="active" smooth spy to="contato" onClick={goToStart}> Avaliação de caso</Link>
            </p>

         </div>
      </header>

  <div id="historia" style={active==="Home"?{backgroundColor: '#E8EDDF'}:{backgroundColor: '#E8EDDF',display:'none'}} >
    
    <section id="hero" className="d-flex align-items-center justify-content-center" ref={home}>
      <div className="container" data-func="fade-up">
        <div className="row justify-content-center" data-func="fade-up" data-func-delay="150">
          <div className="">
            <h1>Vellasques & Negrelli<span></span></h1>
            <h2>Advogados Associados</h2>
        </div>
      </div>
    </div>
  </section>
    
    <ChatBot/>

    <AnimationOnScroll animatePreScroll={false} initiallyVisible={true} animateIn="fadeInLeft">
    <section id="about" className="about" ref={history}>
      <div className="container" data-func="fade-up" >

        <div className="row">
          <div className="col-lg-6 order-1 order-lg-2" style={{transition:'width 2s, height 2s, transform 2s'}}data-func="fade-left" data-func-delay="100">
          
              <img src={about} className="img-fluid" alt=""></img>
            
          </div>

          <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-func="fade-right" data-func-delay="100">
          
            <h3>A Vellasques e Negrelli</h3>
            <p className="fst-italic">
              
            </p>
            <ul>
              <li><i className="bi bi-check2-all"></i> Missão: Consistência e comprometimento com a evolução constante em favor do bem estar dos stakeholders, através da simplicidade, geração e crescimento dos resultados, pautados na confiança e segurança juridica.</li>
              <li><i className="bi bi-check2-all"></i> Visão: Ser reconhecido internacionalmente como referência na prestação de serviços jurídicos!</li>
              <li><i className="bi bi-check2-all"></i> Respeito às diferenças, aos colaboradores, ao meio ambiente, tranparência, excelência no atendimento, compromisso com a inovação, sustentabilidade, qualidade dos serviços, disciplina, lealdade e orientação aos resultados.</li>
            </ul>
            <p>
              Enfrentamento dos compromissos junto as oscilações tecnológicas da atualidade, desenvolvendo continuamente o crescimento dos colaboradores e profissionais, bem como para o desempenho empresarial.            </p>
            
          </div>
        </div>

      </div>
    </section>
    </AnimationOnScroll>
    
    <section id="testimonials" className="testimonials">
      <div className="container" data-func="zoom-in"></div>
    </section>
    <AnimationOnScroll animatePreScroll={false} initiallyVisible={true} animateIn="fadeInLeft">
    <section className="services" ref={services}>
      <div id="services"  className="container" data-func="fade-up">

        <div className="section-title" id="services">
          <h2>Serviços</h2>
          <p>Áreas de Prática Jurídica</p>
        </div>

        <div className="row">
          
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-func="zoom-in" data-func-delay="100">
            <div className="icon-box">
              <h4><p>Penal</p></h4>
              <p>Na busca pelo justo, o escritório de advocacia avança sem tréguas levando aos necessitados seu direito ao devido processo legal, o contraditório e a ampla defesa. Atuando em acompanhamento e defesa em sede de inquéritos policiais..</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-func="zoom-in" data-func-delay="200">
            <div className="icon-box">
              <h4><p>Ambiental</p></h4>
              <p>Esse ramo do Direito busca regular as atividades empresariais e defender a preservação do meio ambiente. Bem de uso comum do povo, que busca atender as gerações presentes e futuras.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-func="zoom-in" data-func-delay="300">
            <div className="icon-box">
              <h4><p>Previdenciário</p></h4>
              <p>O Direito Previdenciário, como ramo do direito público autônomo, tem o objetivo de regulamentar as relações entre o sistema previdenciário e o beneficiário (segurado ou dependente) e pode ser compreendido com um dos direitos fundamentais constantes da Constituição Federal do Brasil.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-func="zoom-in" data-func-delay="100">
            <div className="icon-box">
              <h4><p>Tributário</p></h4>
              <p>A tributação em si está presente na vida de todas as pessoas e possui relevante impacto nas finanças, seja de pessoas físicas ou jurídicas, decorrente da alta carga tributária do sistema brasileiro.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-func="zoom-in" data-func-delay="200">
            <div className="icon-box">
              <h4><p>Trânsito</p></h4>
              <p>As questões de Trânsito vem se tornando um dos maiores problemas atuais, pois diz respeito a locomoção de pessoas e de bens. Com o crescente fluxo de veículos, surge pontos inevitáveis, as autuações e infrações.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-func="zoom-in" data-func-delay="300">
            <div className="icon-box">
              <h4><p>Administrativo</p></h4>
              <p>Um ramo do grande tronco chamado Direito, cuja sustentação é a harmonia entre os princípios com sistemas normativos, que flutuam constantemente, adaptando-se para fazer prevalecer o interesse coletivo.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-func="zoom-in" data-func-delay="300">
            <div className="icon-box">
              <h4><p>Família e Sucessões</p></h4>
              <p>O Direito da Família é um ramo do direito que lida com questões legais relacionadas à família.
                Quatro são os pontos principais deste ramo do direito: A formação da família, Proteção, dissolução e patrimônio.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-func="zoom-in" data-func-delay="300">
            <div className="icon-box">
              <h4><p>Extrajudiciais</p></h4>
              <p>Sabe-se que um processo judicial pode levar bastante tempo para ser solucionado. Por isso, antes de recorrer à Justiça, várias questões podem ser resolvidos através de um procedimento extrajudicial.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-func="zoom-in" data-func-delay="300">
            <div className="icon-box">
              <h4><p>Cível</p></h4>
              <p>O escritório destaca-se na prestação de serviços de acompanhamento, patrocínio e defesa de ações judiciais e procedimentos administrativos das mais diversas espécies relacionadas ao Direito Civil e Empresarial, na esfera nacional, com atuação plena em todo tipo de demanda.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-func="zoom-in" data-func-delay="300">
            <div className="icon-box">
              <h4><p>Regularização de Imóvel</p></h4>
              <p> A regularização de imóveis é um processo importante para garantir a segurança jurídica e a valorização do patrimônio imobiliário. Estar regularizado significa estar de acordo com o que manda a Lei. Caso contrário, o novo proprietário poderá ter problemas e dificuldades para escriturar o imóvel. Para realizá-lo, é fundamental contar com a orientação de profissionais especializados e estar ciente dos procedimentos exigidos pelos órgãos competentes, como Prefeitura e Cartórios. </p>
            </div>
          </div>
          
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-func="zoom-in" data-func-delay="300">
            <div className="icon-box">
              <h4><p>Internacional</p></h4>
              <p>São comuns as negociações e transações internacionais atualmente. Sejam elas de pessoas físicas ou jurídicas em diversos, tratados, regulamentações e práticas comerciais internacionais que precisam ser observadas ao realizar negociações fora do país. Oferecemos uma ampla gama de serviços jurídicos internacionais, incluindo aconselhamento sobre Contratos  internacionais, de compra ou venda, contratos de distribuição, contratos de licenciamento.  </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-func="zoom-in" data-func-delay="300">
            <div className="icon-box">
              <h4><p>Inovação, Tecnologia e Startups</p></h4>
              <p>Modelos inovadores e disruptivos de negócios, exigem um departamento jurídico à altura. Cuidar dos aspectos jurídicos e legais fundamentais para startups e negócios inovadores também faz parte do nosso DNA.  Segurança e tranquilidade para você desenvolver e escalar suas ideias.</p>
            </div>
          </div>
        </div>

      </div>
    </section>
    </AnimationOnScroll>
    
    <section className="cta">
      <div className="container" data-func="zoom-in">

        <div className="text-center" >
          <h3>Avaliação de caso</h3>
          <p className="cta-btn" style={{cursor:'pointer'}} onClick={goToBottom} >Marcar uma consulta</p>
        </div>

      </div>
    </section>
    <section className="contact" id="contato" ref={contact}>
        <div className="row mt-5" >

          <div className="col-lg-4" >
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Endereço:</h4>
                <p onClick={handleMaps} style={{cursor:'pointer'}}>R. Pedro Simões de Oliveira, 609</p>
              </div>

              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p><a href="mailto:vellasquesenegrelliadv.rn@gmail.com" style={{color:'black'}}>vellasquesenegrelliadv.rn@gmail.com</a></p>
              </div>

              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Telefone:</h4>
                <p><span style={{color:'black', cursor:'pointer'}} onClick={handleWhats} >47 3279-6430</span></p>
              </div>

            </div>

          </div>

          <div className="col-lg-8 mt-5 mt-lg-0">
            <form id="formDados" action="" ref={formEmail} onSubmit={sendEmail} method="post">
              <div className="row" style={{marginRight: '-10px'}}>
                <div className="col-md-6 form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Seu Nome" required></input>
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Seu Email" required></input>
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Assunto" required></input>
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="message" rows="5" placeholder="O que deseja?" required></textarea>
              </div>
              <div className="text-center"><button className="button-send" type="submit">Enviar mensagem</button></div>
            </form>
          </div>
        </div>
    </section>


  <footer id="footer">
    <div className="footer-top" >
      <div className="container">
        <div className="row">

        <div className="col-lg-6 col-md-6">
            <div className="footer-info">
                <strong >Endereço:</strong> <span style={{color:'#FFFFFF', cursor:'pointer'}} onClick={handleMaps}>R. Pedro Simões de Oliveira, 609</span><br/>
                <strong >Telefone:</strong> <span style={{color:'#FFFFFF', cursor:'pointer'}} onClick={handleWhats}>47 3279-6430</span><br/>
                <strong>Email:</strong> <a style={{color:'#FFFFFF', cursor:'pointer'}} href="mailto:vellasquesenegrelliadv.rn@gmail.com" >vellasquesenegrelliadv.rn@gmail.com</a><br/>
                <strong>Envie seu curriculo para:</strong> <a style={{color:'#FFFFFF', cursor:'pointer'}} href="mailto:admvellasquesenegrelli@gmail.com" >admvellasquesenegrelli@gmail.com</a>
                <div onClick={goToFacebook} style={{cursor:'pointer'}}target="_blank" rel="noreferrer" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                </svg>
                   {' Vellasques & Negrelli'}
              </div>
              <div onClick={goToInstagram} style={{cursor:'pointer'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
              </svg>
                  {' @vellasquesenegrelli'}
              </div>
              <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tiktok" viewBox="0 0 16 16">
                <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z"/>
              </svg>
              </div>
            </div>
          </div>



          <div className="col-lg-6 col-md-6 footer-newsletter">
            <h4>Nossa News-letter</h4>
            <p>Junte-se à nossa lista para ficar em contato !</p>
            <form id="formNews" action="" ref={formNews} onSubmit={sendNewsletter} method="post">
              <input type="email" name="email"></input>
              <input type="submit" value="Inscreva se"></input>
            </form>

          </div>

        </div>
      </div>
    </div>
  </footer>
</div>

<div style={active==="Blog"?{}:{display:'none'}}>
  <Blog/>
</div>


<script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossOrigin="anonymous"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js" integrity="sha384-ZMP7rVo3mIykV+2+9J3UJ46jBk0WLaUAdn689aCwoqbBJiSnjAK/l8WvCWPIPm49" crossOrigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js" integrity="sha384-ChfqqxuZUCnJSK3+MXmPNIyE6ZbWh2IMqE241rYiqJxyMiZ6OW/JmZQ5stwEULTy" crossOrigin="anonymous"></script>
<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossOrigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ho+j7jyWK8fNQe+A12Hb8AhRq26LrZ/JpcUGGOn+Y7RsweNrtN/tE3MoK7ZeZDyx" crossOrigin="anonymous"></script>
  
    </div>
    
  );
};

