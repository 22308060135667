import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot';

export default function ThemedExample() {

    const theme = {
        background: '#f5f8fb',
        fontFamily: '"Poppins", sans-serif',
        headerBgColor: '#DBBE44',
        headerFontColor: '#fff',
        headerFontSize: '15px',
        botBubbleColor: '#DBBE44',
        botFontColor: '#fff',
        userBubbleColor: '#fff',
        userFontColor: '#4a4a4a',
      };
      
      
      const steps=[
        {
          id: '1',
          message: 'Seja Bem vindo à Vellasques e Negrelli Advogados Associados! Como podemos ajudar?',
          trigger: 'update-question',
        },
        {
          id: 'update-question',
          options: [
            { value: 'Direito Penal', label: 'Direito Penal' ,trigger: '2'},
            { value: 'Direito Ambiental', label: 'Direito Ambiental' ,trigger: '2'},
            { value: 'Direito Previdenciário', label: 'Direito Previdenciário' ,trigger: '2'},
            { value: 'Direito Cível', label: 'Direito Cível' ,trigger: '2'},
            { value: 'Direito Tributário', label: 'Direito Tributário' ,trigger: '2'},
            { value: 'Direito do Trânsito', label: 'Direito do Trânsito' ,trigger: '2'},
            { value: 'Direito Administrativo', label: 'Direito Administrativo' ,trigger: '2' },
            { value: 'Direito Internacional', label: 'Direito Internacional' ,trigger: '2' },
            { value: 'Regularização de Imóveis', label: 'Regularização de Imóveis' ,trigger: '2' },
            { value: 'Família e Sucessões', label: 'Família e Sucessões' ,trigger: '2' },
            { value: 'Extrajudiciais', label: 'Extrajudiciais',trigger: '2'},
            { value: 'Outros', label: 'Outros',trigger: '2'},
          ],
        },
        {
          id: '2',
          message: 'Por gentileza, qual o seu nome?',
          trigger: '3',
        },
        {
          id: '3',
          user: true,
          trigger: '4',
        },
          {
          id: '4',
          message: 'Ok, vou lhe encaminhar para um atendente. ',
          end: true,
        },
      ]
      
      const handleEnd = ({ values }) => {
      
        const message = 'Olá, meu nome é ' + values[1] + ' eu gostaria de conversar sobre ' + values[0] + '.'
        const link = 'https://wa.me/+554732796430?text=' + message
      
        setTimeout(function() {
          window.location.href=link
       }, 2000);
       
      }
      
      
      const [opened, setOpen] = useState();
      
      const toggleFloating = ({ opened }) => {
        setOpen( opened ); 
      }
      


    return (
    <ThemeProvider theme={theme}>
      <ChatBot 
          steps={steps}
          floating={true}
          opened={opened}
          toggleFloating={toggleFloating}
          placeholder='Digite sua mensagem...'
          handleEnd={handleEnd}
        />
    </ThemeProvider>
    )

}