import React from 'react';
import { 
  Route as ReactDOMRouter,
  RouteProps as ReactDOMRouteProps,
} from 'react-router-dom';

interface IRouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isPrivateAndPublic?: boolean;
  component: React.ComponentType | any;
}

const Route: React.FC<IRouteProps> = ({
  isPrivate = false,
  isPrivateAndPublic = false,
  component: Component,
  ...rest
}) => {

  return (
    <ReactDOMRouter
      {...rest}
      render={() => {
          return <Component />;
      }}
    />
  );
};

export default Route;
